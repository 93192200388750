import { INovaEntity } from './base';
import { formatPhoneNumber } from './js-helpers';

export interface IMessageThread extends INovaEntity {
  orgId: string;
  assetVisitId: string;
  messages?: IMessageThreadMessage[];
  expiresAt: Date;
}

export enum IMessageStatus {
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SENT = 'sent',
  RECEIVED = 'received'
}

export interface IMessageThreadMessageFile {
  name: string;
  mimeType: AllowedMessageThreadMediaMimeTypes;
  url: string;
  size: number;
}

export enum AllowedMessageThreadMediaMimeTypes {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  HEIC = 'image/heic',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CSV = 'text/csv',
  TSV = 'text/tab-separated-values'
}

export enum MessageSourceType {
  DRIVER = 'driver',
  WAREHOUSE = 'warehouse',
  SYSTEM = 'system'
}

export interface IMessageThreadMessage {
  providerMessageId: string;
  orgId?: string;
  messageThreadId: string;
  timestamp?: Date;
  content: string;
  files?: IMessageThreadMessageFile[];
  toNumber: string;
  fromNumber: string;
  status: IMessageStatus;
  createdBy?: string;
  sourceType?: MessageSourceType;
}

export interface IMessageThreadEvent {
  orgId: string;
  messageThreadId: string;
  content: string;
}

export function isMessageThreadExpired(messageThread: IMessageThread): boolean {
  const expiresAtUTC = new Date(messageThread.expiresAt).getTime();
  const nowUTC = new Date().getTime();
  return nowUTC > expiresAtUTC;
}

enum SubscriptionKeyword {
  START = 'start',
  STOP = 'stop',
  UNSTOP = 'unstop'
}

export function isDriverSubscribing(content: string): boolean {
  const c = content?.toLowerCase();
  return c === SubscriptionKeyword.START || c === SubscriptionKeyword.UNSTOP;
}

export function isDriverUnsubscribing(content: string): boolean {
  return content?.toLowerCase() === SubscriptionKeyword.STOP;
}

export const SystemMessages = {
  ERROR: {
    DEFAULT: () =>
      'Automated Response: An error occurred while processing your message. Please try again later.',
    INVALID_FILE_TYPE: (fileType?: string) =>
      `Automated Response: Sorry, your message was not delivered. ${
        fileType
          ? `The file type ${fileType} is not supported.`
          : 'This file type is not supported.'
      }`,
    THREAD_NOT_FOUND: () =>
      'Automated Response: Sorry, your message was not delivered. There are no active conversations with this warehouse.'
  },
  EVENT: {
    DRIVER_SUBSCRIBED: () => 'The driver has opted to start receiving messages',
    DRIVER_UNSUBSCRIBED: () => 'The driver has opted to stop receiving messages',
    PHONE_CHANGED: (phone: string) => `Driver's phone changed to ${formatPhoneNumber(phone)}`,
    THREAD_OPEN: () => 'Chat resumed by the warehouse',
    THREAD_CLOSED: () => 'Chat ended by the warehouse'
  },
  INFO: {
    DRIVER_GREETING: (warehouseName: string) =>
      `Thanks for checking in at ${warehouseName}. Instructions will follow to this phone number. Reply STOP to unsubscribe.`
  }
};
