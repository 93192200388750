/* eslint-disable max-lines */
import { isArray, isDate, isDateString, isString, isUUID } from 'class-validator';
import { DateTime } from 'luxon';
import * as PasswordValidator from 'password-validator';
import { userConstants } from '../constants';
import {
  GlobalLimits,
  IAppointment,
  diffBetweenDates,
  hasOnlyCCEmails,
  hasOnlyTags,
  isTrulyEmpty
} from '../src';
import { INovaEntity, NovaId } from './base';
import { SELF_CHECKIN_EMAIL } from './checkin';
import { isValidInternalEmail } from './js-helpers';
import { IHasOrgId } from './org';

export const MAX_WAREHOUSE_PER_USER = GlobalLimits.MAX_WAREHOUSE_PER_USER.value;
export const PASSWORD_SALT_LENGTH = 8;

export const PASSWORD_RESET_EMAIL_THROTTLE_IN_MINUTES = 5;

export interface IUser extends INovaEntity, IHasOrgId {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  phone?: string;
  extension?: string;
  isEmailVerified: boolean;
  role: UserRole;
  appointments?: IAppointment[];
  invalidLoginAttempts?: number;
  companyId?: NovaId;
  orgId?: NovaId;
  orgIsActive?: boolean;
  orgName?: string;
  warehouseAccessList?: NovaId[];
  tcConfirmedAt?: Date;
  lastLoginAt?: Date;
  passwordResetRequired?: boolean;
  passwordResetEmailSentAt?: Date;
  internalLoginUserId?: string;
}

// IMPORTANT: The order of the individual roles
// below matters! Each role should be "more powerful" than
// the one below it.
export enum UserRole {
  // This role should be used sparingly, maybe even temporary with a cron to lower the role each day
  GOD = 'role_god',
  // CSAs, and others use this role to allow them to modify users and loginAs other users
  INTERNAL = 'role_internal',

  // Standard roles for warehouse users
  OWNER = 'role_owner',
  ADMIN = 'role_admin',
  OPERATOR = 'role_operator',
  ATTENDANT = 'role_attendant',
  SPECTATOR = 'role_spectator',

  // Standard roles for carrier users
  CARRIER_ADMIN = 'role_carrier_admin',
  CARRIER = 'role_carrier',

  // Initial role before being promoted.
  BASE = 'role_base'
}

export enum UserType {
  CARRIER = 'carrierUser',
  INTERNAL = 'internalUser',
  WAREHOUSE = 'warehouseUser',
  BASE = 'baseUser'
}

export const CARRIER_ROLES = Object.freeze([
  UserRole.BASE,
  UserRole.CARRIER,
  UserRole.CARRIER_ADMIN
]) as UserRole[];

export function getUserType(user: IUser): UserType {
  // if they have an internal role, then they are internal no matter what.
  if ([UserRole.GOD, UserRole.INTERNAL].includes(user.role)) {
    return UserType.INTERNAL;
  }
  // then if they have a warehouse role, they are a warehouse user (other checks should enforce them to have an orgId)
  if (
    [
      UserRole.OWNER,
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.ATTENDANT,
      UserRole.SPECTATOR
    ].includes(user.role)
  ) {
    return UserType.WAREHOUSE;
  }
  // failing the checks above, we want to determine if they are a carrier or a base user
  if (
    !isTrulyEmpty(user.companyId) ||
    [UserRole.CARRIER, UserRole.CARRIER_ADMIN].includes(user.role)
  ) {
    return UserType.CARRIER;
  }

  return UserType.BASE;
}

export function getRoleActionError() {
  return 'Your role does not allow you to perform this action';
}

export function canUserAccessWarehouse(user: IUser, warehouseId: NovaId, warehouseOrgId?: NovaId) {
  /*
   warehouseOrgId is optional so this method can keep its original functionality when it is too
   difficult to reasonably get the warehouse.orgId
   This allows us to be more correct and secure when we are able, but use the current functionality
   when we are not.
    - Carriers and Internal users have access to all warehouses.
    - Base users do not have access to ANY warehouses.
    - WarehouseUsers should only have access to 1) warehouses within their org and 2) not restricted FROM
    the warehouse via warehouseAccessList or permitted via their role
   */
  const userType = getUserType(user);

  if ([UserType.CARRIER, UserType.INTERNAL].includes(userType)) {
    return true;
  }

  if (UserType.BASE === userType) {
    return false;
  }

  // this is the added security to ensure the warehouse and the user are in the same org
  if (warehouseOrgId && user.orgId !== warehouseOrgId) {
    return false;
  }

  return !isUserWarehouseRestricted(user) || user.warehouseAccessList.includes(warehouseId);
}

export function isUserWarehouseRestricted(user: IUser) {
  return canUserRoleHaveWarehouseAccessList(user.role) && isArray(user.warehouseAccessList);
}

// This is a list of User Roles that *can* be further limited to a subset of Warehouses. The other roles
// simply follow the pre-existing Org access rules.
export function canUserRoleHaveWarehouseAccessList(role: UserRole) {
  return (
    role &&
    [UserRole.ADMIN, UserRole.OPERATOR, UserRole.ATTENDANT, UserRole.SPECTATOR].includes(role)
  );
}

export function getJwtExpirationTimeByRole(role: UserRole) {
  return {
    [UserRole.GOD]: '1h',
    [UserRole.INTERNAL]: '1d',
    [UserRole.OWNER]: '3d',
    [UserRole.ADMIN]: '3d',
    [UserRole.OPERATOR]: '30d',
    [UserRole.ATTENDANT]: '30d',
    [UserRole.SPECTATOR]: '30d',
    [UserRole.CARRIER_ADMIN]: '30d',
    [UserRole.CARRIER]: '30d',
    [UserRole.BASE]: '30d'
  }[role];
}

export function getValidInviteeRoles() {
  return [
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.OPERATOR,
    UserRole.ATTENDANT,
    UserRole.SPECTATOR
  ];
}

export function userRoleDescription(role: UserRole) {
  return {
    // Placeholders for GOD and INTERNAL since this doesn't get displayed anywhere at the moment
    [UserRole.GOD]: 'All permissions and full control over all accounts.',
    [UserRole.INTERNAL]: 'GOD role with less control.',

    [UserRole.OWNER]: 'All permissions and full control over the Organization account.',
    [UserRole.ADMIN]:
      'Create/edit/delete Appointments, Warehouses, Load Types, Docks, and Users (except Owners).',
    [UserRole.OPERATOR]: 'Create/edit/cancel Appointments.',
    [UserRole.ATTENDANT]: 'Edit Appointment Status, tags, and subscribers.',
    [UserRole.SPECTATOR]: 'View only. No create or edit actions allowed.',

    [UserRole.CARRIER_ADMIN]: 'Carrier administrator.',
    [UserRole.CARRIER]: 'Carrier user',

    role_base: 'Base user account.'
  }[role];
}

export function userRoleToText(role: UserRole) {
  return {
    role_god: 'God',
    role_internal: 'Internal',

    role_owner: 'Owner',
    role_admin: 'Admin',
    role_operator: 'Operator',
    role_attendant: 'Attendant',
    role_spectator: 'Spectator',

    role_carrier_admin: 'Carrier Admin',
    role_carrier: 'Carrier',

    role_base: 'Base'
  }[role];
}

export function hasEmptyOrgId(user: IUser): boolean {
  return validateUser(user) && !user.orgId;
}

export function isOmnipotentUser(user: IUser): boolean {
  return validateUser(user) && user.role === UserRole.GOD;
}

export function isIdenticalUser(user1: IUser, user2: IUser): boolean {
  return (
    validateUser(user1) && validateUser(user2) && user1.id && user2.id && user1.id === user2.id
  );
}

export function isStrictInternalRole(role: UserRole): boolean {
  return validateRole(role) && role === UserRole.INTERNAL;
}

export function isInternalRole(role: UserRole): boolean {
  return validateRole(role) && (role === UserRole.GOD || role === UserRole.INTERNAL);
}

export function isInternalUser(user: IUser): boolean {
  return validateUser(user) && isUserRoleValid(user.role) && isInternalRole(user.role);
}

export function isNormalUser(user: IUser): boolean {
  return validateUser(user) && isUserRoleValid(user.role) && !isInternalUser(user);
}

export function isBaseUser(user: IUser): boolean {
  return validateUser(user) && isUserRoleValid(user.role) && user.role === UserRole.BASE;
}

export function isWarehouseUser(user: IUser): boolean {
  return (
    validateUser(user) &&
    isUserRoleValid(user.role) &&
    !isBaseUser(user) &&
    !isInternalUser(user) &&
    !isCarrierUser(user)
  );
}

export function isSelfCheckInUser(user: IUser): boolean {
  return validateUser(user) && isUserRoleValid(user.role) && user.email === SELF_CHECKIN_EMAIL;
}

// A Carrier is now distinct from just a "BASE", and is defined
// as a BASE who is attached to a valid Company, but no Org.
export function isCarrierUser(user: IUser): boolean {
  // Keep the BASE as also a carrier for the time being
  const isCarrierV1 = Boolean(user.companyId) && !user.orgId && user.role === UserRole.BASE;
  const isCarrierV2 = [UserRole.CARRIER, UserRole.CARRIER_ADMIN].includes(user.role) && !user.orgId;
  const isCarrierUser = isCarrierV1 || isCarrierV2;
  return validateUser(user) && isUserRoleValid(user.role) && isCarrierUser;
}

export function isOwner(user: IUser): boolean {
  return validateUser(user) && isUserRoleValid(user.role) && user.role === UserRole.OWNER;
}

export function doesUserHaveOrgRole(user: IUser): boolean {
  const validRoles = [
    UserRole.OWNER,
    UserRole.ADMIN,
    UserRole.OPERATOR,
    UserRole.ATTENDANT,
    UserRole.SPECTATOR
  ];

  return validateUser(user) && validRoles.includes(user.role);
}

// Internal users may impersonate another user via Wormhole
export function isLoggedInAsAnotherUser(user: IUser) {
  return (
    validateUser(user) &&
    user.id !== userConstants.odcInternalUserId &&
    isUUID(user?.internalLoginUserId)
  );
}

export function getAuthenticatedUserId(user: IUser) {
  return isLoggedInAsAnotherUser(user) ? user.internalLoginUserId : user.id;
}

export function canUserUpdateBilling(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.OWNER);
}

export function canUserReadBilling(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.OWNER);
}

export function canUserReadSubscriptions(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN);
}

export function canUserDeleteDocks(user: IUser): boolean {
  return isInternalUser(user) || canUserCreateDocks(user);
}

export function canUserCreateDocks(user: IUser): boolean {
  return isInternalUser(user) || (doesUserHaveOrgRole(user) && canUserUpdateWarehouse(user));
}

export function canUserUpdateDocks(user: IUser): boolean {
  return canUserCreateDocks(user);
}

export function canUserInviteUsers(user: IUser): boolean {
  return doesUserHaveOrgRole(user) && canUserUpdateWarehouse(user);
}

export function canUserCreateLoadTypes(user: IUser): boolean {
  return doesUserHaveOrgRole(user) && canUserUpdateWarehouse(user);
}

export function canUserUpdateLoadTypes(user: IUser): boolean {
  return canUserDeleteLoadTypes(user);
}

export function canUserDeleteLoadTypes(user: IUser): boolean {
  return isInternalUser(user) || canUserCreateLoadTypes(user);
}

export function canUserBookInThePast(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR);
}

export function canUserUpdateFavoriteCarriers(user: IUser, orgId: string): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR) && user.orgId === orgId;
}

export function canUserUpdateCustomTags(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN);
}

export function canUserReadNotificationConfig(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN);
}

export function canUserCreateNotificationConfig(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN);
}

export function canUserUpdateNotificationConfig(user: IUser): boolean {
  return isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN);
}

export function isUserInSameOrg(user1: IUser, user2: IUser): boolean {
  return (
    validateUser(user1) &&
    validateUser(user2) &&
    user1.orgId &&
    user2.orgId &&
    user1.orgId === user2.orgId
  );
}

export function canUserCreateWarehouse(user: IUser): boolean {
  return Boolean(
    validateUser(user) &&
      !isUserWarehouseRestricted(user) &&
      isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN) &&
      !hasEmptyOrgId(user)
  );
}

export function canUserUpdateWarehouse(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN));
}

export function canUserDeleteWarehouse(user: IUser): boolean {
  return canUserUpdateWarehouse(user) && !isUserWarehouseRestricted(user);
}

export function canUserCreateWarehouseGroup(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN));
}
export function canUserUpdateWarehouseGroup(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN));
}

export function canUserDeleteWarehouseGroup(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN));
}

export function canUserModifyApmtStatus(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreater(user.role, UserRole.SPECTATOR));
}

export function canUserModifyStatusTimeline(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreater(user.role, UserRole.SPECTATOR));
}

export function canUserDeleteAppointment(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreater(user.role, UserRole.ATTENDANT));
}

export function canUserModifyCustomFormData(user: IUser): boolean {
  return Boolean(validateUser(user) && user.role !== UserRole.SPECTATOR);
}

export function canUserCancelAppointment(user: IUser): boolean {
  return Boolean(
    validateUser(user) && (isUserRoleGreater(user.role, UserRole.ATTENDANT) || isCarrierUser(user))
  );
}

export function canUserCreateAppointment(user: IUser): boolean {
  return Boolean(
    (validateUser(user) && isUserRoleGreater(user.role, UserRole.ATTENDANT)) || isCarrierUser(user)
  );
}

export function canUserCreateReserve(user: IUser): boolean {
  return canUserCreateAppointment(user) && !isCarrierUser(user);
}

export function canUserUpdateAppointment(user: IUser, appt?: Partial<IAppointment>): boolean {
  if (hasOnlyCCEmails(appt)) {
    return canUserUpdateAppointmentEmailCCs(user);
  }
  if (hasOnlyTags(appt)) {
    return canUserUpdateAppointmentTags(user);
  }
  return canUserCreateAppointment(user);
}

export function canUserUpdateAppointmentEmailCCs(user: IUser): boolean {
  return Boolean(
    (validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ATTENDANT)) ||
      isCarrierUser(user)
  );
}

export function canUserUpdateAppointmentTags(user: IUser): boolean {
  return Boolean(
    (validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ATTENDANT)) ||
      isCarrierUser(user)
  );
}

export function canUserDeleteUserById(user: IUser, deletedUserId: string): boolean {
  return Boolean(
    validateUser(user) &&
      isUserRoleGreater(user.role, UserRole.OPERATOR) &&
      deletedUserId !== user.id
  );
}

export function canUserDeleteUser(user: IUser, deletedUser: IUser): boolean {
  return Boolean(
    validateUser(deletedUser) &&
      canUserDeleteUserById(user, deletedUser.id) &&
      isUserRoleLessOrEqual(deletedUser.role, user.role)
  );
}

export function getUserRoles(): string[] {
  return Object.keys(UserRole);
}

export function getUserRoleValues(): string[] {
  return Object.values(UserRole);
}

export function compareUserRoles(role1: UserRole, role2: UserRole): number {
  const roleValues = getUserRoleValues();

  const index1 = roleValues.indexOf(role1);
  const index2 = roleValues.indexOf(role2);

  return validateRole(role1) && validateRole(role2) && index2 - index1;
}

export function isUserRoleGreaterOrEqual(role1: UserRole, role2: UserRole): boolean {
  return compareUserRoles(role1, role2) >= 0;
}

export function isUserRoleLessOrEqual(role1: UserRole, role2: UserRole): boolean {
  return compareUserRoles(role1, role2) <= 0;
}

export function isUserRoleLess(role1: UserRole, role2: UserRole): boolean {
  return !isUserRoleGreaterOrEqual(role1, role2);
}

export function isUserRoleGreater(role1: UserRole, role2: UserRole): boolean {
  return !isUserRoleLessOrEqual(role1, role2);
}

export function isUserRoleValid(role: UserRole): boolean {
  return Boolean(role && getUserRoleValues().includes(role));
}

export function isUserValid(user: IUser): boolean {
  const validUserFields = ['id', 'email', 'orgId', 'companyId', 'role'];
  return Boolean(
    user instanceof Object && Object.keys(user).find(k => validUserFields.includes(k))
  );
}

export function isPasswordResetRequired(user: Partial<IUser>): boolean {
  return Boolean(user.passwordResetRequired);
}

export function validateUser(user: IUser): boolean {
  if (!isUserValid(user)) {
    throw new Error(`Core: validateUser(): invalid User ${user}`);
  }
  return true;
}

export function validateRole(role: UserRole): boolean {
  if (!isUserRoleValid(role)) {
    throw new Error(`Core: validateRole(): invalid User Role ${role}`);
  }
  return true;
}

export function canUserReadOrgData(user: IUser, orgId: string): boolean {
  return Boolean(
    isInternalUser(user) ||
      (isUserRoleGreaterOrEqual(user.role, UserRole.SPECTATOR) && user.orgId === orgId)
  );
}

export function canUserDeleteOrg(user: IUser, orgId: string): boolean {
  return Boolean(
    isInternalUser(user) ||
      (isUserRoleGreaterOrEqual(user.role, UserRole.OWNER) && user.orgId === orgId)
  );
}

export function canUserCreateOrg(user: IUser): boolean {
  return Boolean(
    validateUser(user) &&
      hasEmptyOrgId(user) &&
      ![UserRole.CARRIER_ADMIN, UserRole.CARRIER].includes(user.role)
  );
}

export function canUserUpdateOrg(user: IUser, orgId: string): boolean {
  return Boolean(
    isInternalUser(user) ||
      (isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN) &&
        !isUserWarehouseRestricted(user) &&
        user.orgId === orgId)
  );
}

export function canUserCreateUser(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreater(user.role, UserRole.OPERATOR));
}

export function canUserUpdateUser(user: IUser, userId: string): boolean {
  return (
    user.id === userId || (validateUser(user) && isUserRoleGreater(user.role, UserRole.OPERATOR))
  );
}

export function doesWarehouseAccessAllowUpdate(loggedInUser: IUser, targetUser: IUser): boolean {
  if (isUserWarehouseRestricted(loggedInUser)) {
    const loggedInUserWarehouses = loggedInUser.warehouseAccessList;
    const targetUserWarehouses = targetUser?.warehouseAccessList;

    // Make sure targetUserWarehouses is a subset of the loggedInUserWarehouses
    return (
      isUserWarehouseRestricted(targetUser) &&
      targetUserWarehouses.every((wId: NovaId) => loggedInUserWarehouses.includes(wId))
    );
  }

  return true;
}

export function isRestrictedUserAttemptingToUnRestrictUser(
  loggedInUser: IUser,
  targetUser: IUser,
  dto: Partial<IUser>
): boolean {
  return (
    isUserWarehouseRestricted(loggedInUser) &&
    isUserWarehouseRestricted(targetUser) &&
    (dto.warehouseAccessList === null || dto.warehouseAccessList?.length === 0)
  );
}

export function canUserCreateCarrier(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR));
}

export function canUserCreateReportSearch(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR));
}

export function canUserDeleteReportSearch(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR));
}

export function canUserUpdateCarrier(user: IUser): boolean {
  return isInternalUser(user);
}

export function canRole1UpdateUser2ToRole3(
  loggedInUserRole: UserRole,
  updatedUser: IUser,
  newUserRole: UserRole
): boolean {
  if (isStrictInternalRole(loggedInUserRole)) {
    const isInternalPromotingValidEmailInternal =
      isStrictInternalRole(newUserRole) &&
      isUserRoleGreater(loggedInUserRole, updatedUser.role) &&
      isValidInternalEmail(updatedUser.email);

    const isInternalPromotingAnyUser =
      !isInternalRole(newUserRole) && isUserRoleGreaterOrEqual(loggedInUserRole, updatedUser.role);

    return isInternalPromotingValidEmailInternal || isInternalPromotingAnyUser;
  }

  return (
    isUserRoleGreaterOrEqual(loggedInUserRole, newUserRole) &&
    isUserRoleGreaterOrEqual(loggedInUserRole, updatedUser.role)
  );
}

export function canUserChangeOrgCarrierSettings(user: IUser): boolean {
  return validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR);
}

export function canUserReadOrgCarrierSettings(user: IUser): boolean {
  return validateUser(user);
}

export function canUserConfigureSaml(user: IUser): boolean {
  return (
    isWarehouseUser(user) &&
    !isUserWarehouseRestricted(user) &&
    isUserRoleGreaterOrEqual(user.role, UserRole.ADMIN)
  );
}

export function getPasswordValidationErrors(password: string): any[] {
  const schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .symbols() // Must have special charaters
    .has()
    .digits(1); // Must have at least 1 digit
  const passwordValidationErrors = schema.validate(password, { list: true }) as any[];

  const returnedErrors = [];

  returnedErrors.push({
    error: 'min',
    message: 'Must have at least 8 characters',
    passed: !passwordValidationErrors.includes('min')
  });
  returnedErrors.push({
    error: 'uppercase-and-lowercase',
    message: 'Must contain upper-case and lower-case',
    passed: !(
      passwordValidationErrors.includes('lowercase') ||
      passwordValidationErrors.includes('uppercase')
    )
  });
  returnedErrors.push({
    error: 'one-letter',
    message: 'Must contain at least one letter',
    passed: !(
      passwordValidationErrors.includes('lowercase') &&
      passwordValidationErrors.includes('uppercase')
    )
  });
  returnedErrors.push({
    error: 'symbols',
    message: 'Must contain at least one special character',
    passed: !passwordValidationErrors.includes('symbols')
  });
  returnedErrors.push({
    error: 'one-number',
    message: 'Must contain at least one number',
    passed: !passwordValidationErrors.includes('digits')
  });

  return returnedErrors;
}

/**
 * Generates the password reset required error message based on the user's email
 * and the time between the last password reset email and the current time.
 *
 * @param {string} userEmail - The user's email address.
 * @param {number} minutesBetweenLastEmailAndNow - The time between the last password reset email and the current time.
 * @returns {string} The formatted error message.
 */
export function getPasswordResetRequiredErrorMessage(
  userEmail: string,
  minutesBetweenLastEmailAndNow = 0
) {
  if (!userEmail || !isString(userEmail)) {
    throw new Error('invalid user email');
  }
  const errorMessage = `Your password must be reset for continued access to Opendock. A password reset email has been sent to ${userEmail}`;
  const additionalErrorMessage =
    minutesBetweenLastEmailAndNow > 0 &&
    minutesBetweenLastEmailAndNow <= PASSWORD_RESET_EMAIL_THROTTLE_IN_MINUTES
      ? ` within the last ${PASSWORD_RESET_EMAIL_THROTTLE_IN_MINUTES} minutes`
      : '';
  return `${errorMessage}${additionalErrorMessage}.`;
}

/**
 * Calculates the time difference in minutes between the last password reset email sent
 * and the current time.
 *
 * @param {passwordResetEmailSentAt: Date} user - The user object with password reset email information.
 * @returns {number} The time difference in minutes.
 */
export function getDiffBetweenPasswordResetEmailAndNow(passwordResetEmailSentAt: Date) {
  let lastEmailSentAt = passwordResetEmailSentAt;
  if (isDateString(passwordResetEmailSentAt)) {
    lastEmailSentAt = DateTime.fromISO(String(passwordResetEmailSentAt)).toJSDate();
  }

  if (!isDate(lastEmailSentAt)) {
    throw new Error('invalid Date');
  }
  let minutesBetweenLastEmailAndNow = 0;
  if (lastEmailSentAt) {
    minutesBetweenLastEmailAndNow = diffBetweenDates({
      startDateStr: lastEmailSentAt,
      endDateStr: new Date(),
      diffUnit: 'minutes'
    });
  }
  return minutesBetweenLastEmailAndNow;
}

export function canUserCreateSpot(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR));
}

export function canUserUpdateSpot(user: IUser): boolean {
  return canUserCreateSpot(user);
}

export function canUserDeleteSpot(user: IUser): boolean {
  return canUserCreateSpot(user);
}

export function canUserCreateSpotArea(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.OPERATOR));
}

export function canUserUpdateSpotArea(user: IUser): boolean {
  return canUserCreateSpotArea(user);
}

export function canUserDeleteSpotArea(user: IUser): boolean {
  return canUserCreateSpotArea(user);
}

export function canUserCreateSpotAssignment(user: IUser): boolean {
  return Boolean(validateUser(user) && isUserRoleGreaterOrEqual(user.role, UserRole.ATTENDANT));
}

export function canUserUpdateSpotAssignment(user: IUser): boolean {
  return canUserCreateSpotAssignment(user);
}

export function canUserDeleteSpotAssignment(user: IUser): boolean {
  return canUserCreateSpotAssignment(user);
}
