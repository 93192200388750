export enum SpotType {
  PARKING = 'parking',
  DOCKING = 'docking'
}

export enum SpotEventTypeEnum {
  ASSIGNMENT = 'assignment',
  RELEASE = 'release',
  CLOSE = 'close',
  OPEN = 'open',
  OTHER = 'other'
}
